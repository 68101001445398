import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { Spinner } from "reactstrap";
import Customers from "../../pages/Customers";
import { keys } from "../../query/keys";
import { getAllCustomers, getAllCustomersSearch } from "../../query/queryFunctions";
import debounce from 'lodash/debounce';

const CustomersContainer = () => {
  const [searchText, setSearchText] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [previousPage, setPreviousPage] = useState("");
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [searchData, setSearchData] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const { isLoading, refetch, data: allCustomersList } = useQuery(
    [keys.customers, nextPage, previousPage],
    () => getAllCustomers({ nextPage, previousPage }),
    {
      onSuccess: (data) => {
        // Check if there's more data available
        if (data.has_more !== undefined) {
          setHasMore(data.has_more);
        }
      }
    }
  );

  const handleSearch = async (value) => {
    try {
      const responseByName = await getAllCustomersSearch({
        searchType: "name",
        value: value,
      });
      const responseByEmail = await getAllCustomersSearch({
        searchType: "email",
        value: value,
      });
      const combinedResults = [...responseByName, ...responseByEmail];
      const uniqueResults = Array.from(new Set(combinedResults.map(c => c.id)))
        .map(id => combinedResults.find(c => c.id === id));
      setSearchData(uniqueResults);
      setIsSearchActive(true);  
      setSearchPage(1); 
    } catch (error) {
      console.error("Error searching customers:", error);
    }
  };

  const debouncedHandleSearch = useCallback(
    debounce(handleSearch, 500),
    []
  );

  const handleSearchTextChange = (value) => {
    setSearchText(value);
    if (value === "") {
      setSearchData(null);
      setIsSearchActive(false);
      refetch();
      setPage(1);
    } else {
      debouncedHandleSearch(value);
    }
  };

  useEffect(() => {
    if (searchText === "" && !searchData) {
      refetch();
    }
  }, [searchText, searchData, refetch]);

  const filterCustomers = () => {
    if (searchData) {
      return searchData;
    }
    return allCustomersList?.data || [];
  };

  if (isLoading) {
    return <Spinner />;
  }

  const handleNextPage = () => {
    const customers = filterCustomers();
    if (!customers.length || !hasMore) return;

    if (isSearchActive) {
      const lastItem = customers[customers.length - 1];
      if (searchPage * 10 < customers.length) {
        setSearchPage(searchPage + 1);
      }
    } else {
      const lastItem = customers[customers.length - 1];
      setNextPage(lastItem.id);
      setPage(page + 1);
      refetch();
    }
  };

  const handlePreviousPage = () => {
    const customers = filterCustomers();
    if (!customers.length || page === 1) return;

    if (isSearchActive) {
      if (searchPage > 1) {
        setSearchPage(searchPage - 1);
      }
    } else {
      const firstItem = customers[0];
      setPreviousPage(firstItem.id);
      setPage(page - 1);
      refetch();
    }
  };

  const paginatedCustomers = () => {
    if (isSearchActive) {
      const startIndex = (searchPage - 1) * 10;
      return filterCustomers().slice(startIndex, startIndex + 10);
    } else {
      return filterCustomers();
    }
  };

  return (
    <Customers
      customers={paginatedCustomers()}
      handleSearchTextChange={handleSearchTextChange}
      handleNextPage={handleNextPage}
      handlePreviousPage={handlePreviousPage}
      page={isSearchActive ? searchPage : page}
      searchText={searchText}
      hasMore={hasMore}
    />
  );
};

export default CustomersContainer;

